// DashboardPaper
/**
 * Simple standardized "paper" container used throughout the dashboard
 * @packageDocumentation
 */
import React from 'react';

import { DashboardPaperProps } from './DashboardPaper.types';

const DashboardPaper: React.FC<DashboardPaperProps> = ({
  className,
  children,
}) => {
  return (
    <div
      data-testid='DashboardPaper'
      className={`p-5 bg-white shadow-md rounded-xl font-light ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};

export default DashboardPaper;
