import { ITestimonial, TestimonialStatusType } from '@types';
import { db, serverTimestamp } from '.';

/**
 * Save a testimonial during the public collection flow
 * Creates or updates in firestore depending on whether uid exists already
 *
 * @param testimonial - the testimonial data to be added to firestore as a new testimonial
 */
export async function saveTestimonial(testimonial: ITestimonial) {
  if (testimonial.uid) {
    return await update(testimonial);
  } else {
    return await create(testimonial);
  }
}

export async function updateReviewerName(testimonialId: string, name: string) {
  const testRef = db.collection('testimonials').doc(testimonialId);

  await testRef.update({ 'userInfo.name': name });
}

/**
 *
 * @param testimonial - the testimonial data to be added to firestore as a new testimonial
 * @returns the new testimonial with its uid
 */
async function create(testimonial: ITestimonial) {
  // get a new ref
  const dbRef = db.collection('testimonials').doc();
  // prepare the new testimonial
  const newTestimonial: ITestimonial = {
    ...testimonial,
    // all new testimonials should be set to pending
    status: 'pending' as TestimonialStatusType,
  };
  // add the new record to firestore
  await dbRef.set({
    ...newTestimonial,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

  // return the testimonial
  return {
    ...newTestimonial,
    uid: dbRef.id,
  };
}

/**
 * updates a testimonial with an existing record at testimonial.uid
 *
 * @param testimonial - the testimonial data to be updated in the database
 */
async function update(testimonial: ITestimonial) {
  const { uid, ...data } = testimonial;
  const dbRef = db.collection('testimonials').doc(uid);

  // update the new data in firestore
  await dbRef.set(
    {
      ...data,
      updatedAt: serverTimestamp(),
    },
    { merge: true }
  );

  return testimonial;
}
