import React, { useState } from 'react';

import dynamic from 'next/dynamic';
// outing & page loading
import Head from 'next/head';
import { useRouter } from 'next/router';
import DefaultErrorPage from 'next/error';
import { GetServerSideProps } from 'next';

import { validateURL } from 'lib/helpers';
import { getCompanyInfo, getCompanyPage } from 'lib/firebase/pages';
import {
  ICompany,
  IFeaturesFeedback,
  IPageFeedback,
  IPageVirtualSales,
  ITestimonial,
} from '@types';
import * as isConfigured from 'lib/flowConfiguration';
import isAccountPaused from 'lib/helpers/isAccountPaused';

// page elements
import Loader from 'components/common/Loader';
import BrandedPage from 'components/common/BrandedPage';
import BrandedPageHead from 'components/common/BrandedPageHead';
import PausedPublicPageDefault from 'components/public/PausedPublicPageDefault';

/** load flow processes dynamically */
const ChatFlow = dynamic(
  () => import('components/public/PublicTestimonialFlow/ChatFlow'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const FeedbackFlow = dynamic(
  () => import('components/public/PublicTestimonialFlow/FeedbackFlow'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const CollectTestimonial = dynamic(
  () => import('components/public/PublicTestimonialFlow/CollectTestimonial'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const CollectUserInfo = dynamic(
  () => import('components/public/PublicTestimonialFlow/CollectUserInfo'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const Incentives = dynamic(
  () => import('components/public/PublicTestimonialFlow/Incentives'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const ResolutionFlow = dynamic(
  () => import('components/public/PublicTestimonialFlow/ResolutionFlow'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const ShareToCompany = dynamic(
  () => import('components/public/PublicTestimonialFlow/ShareToCompany'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const ShareToNetwork = dynamic(
  () => import('components/public/PublicTestimonialFlow/ShareToNetwork'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

const CollectEmail = dynamic(
  () => import('components/public/PublicTestimonialFlow/CollectEmail'),
  {
    loading: () => <Loader />,
    ssr: false,
  }
);

type FeedbackPageProps = {
  company: ICompany;
  pageData: IPageFeedback;
  features: IFeaturesFeedback;
  virtualSalesPageData: IPageVirtualSales;
};

const STEP = {
  chatFlow: 'chatFlow',
  feedbackFlow: 'feedbackFlow',
  collectTestimonial: 'collectTestimonial',
  collectUserInfo: 'collectUserInfo',
  incentives: 'incentives',
  shareToCompany: 'shareToCompany',
  shareToNetwork: 'shareToNetwork',
  collectEmail: 'collectEmail',
  resolutionFlow: 'resolutionFlow',
};

function virtualSalesPageConfigured(
  company: ICompany,
  virtualSales: IPageVirtualSales
) {
  return (
    company?.features?.virtualSales &&
    virtualSales &&
    !virtualSales.excludeFromCollectionFlow &&
    virtualSales?.headline &&
    virtualSales?.description &&
    virtualSales?.ctaButtonURL
  );
}

/**
 * order the flow steps skipping steps that are restricted by feature permissions
 * or require additional configuration
 *
 * @param features - the company's feature permissions for the feedback features
 * @param data - the customization data for the feedback flow
 * @returns - array of ordered flow steps
 */
const getFlowSteps = (
  features: IFeaturesFeedback,
  data: IPageFeedback
): string[] => {
  const flowOrder = [];
  // chat flow
  if (features.chatFlow && isConfigured.chatFlow(data.chatFlow)) {
    flowOrder.push(STEP.chatFlow);
  }
  // feedback flow
  if (features.feedbackFlow && isConfigured.feedbackFlow(data.feedbackFlow)) {
    flowOrder.push(STEP.feedbackFlow);
  }
  // collect testimonial
  flowOrder.push(STEP.collectTestimonial);
  // collect user info if chat flow wasn't allowed
  if (!features.chatFlow || !isConfigured.chatFlow(data.chatFlow)) {
    flowOrder.push(STEP.collectUserInfo);
  }

  // incentives
  if (
    features.incentives && // feature is allowed by permissions
    isConfigured.incentives(data.incentives || null) // incentive data is configured
  ) {
    /**
     * when the user has the incentives feature ensure that incentivizable features are only run once,
     * either as part of the incentives flow (if set as an incentives action) or before it
     */
    // share to company flow, if configured and not an incentive action
    if (
      features.shareToCompany &&
      isConfigured.shareToCompany(data.shareToCompany) &&
      !(
        data.incentives?.baseAction === 'shareToCompany' ||
        data.incentives?.raisedAction === 'shareToCompany'
      )
    ) {
      flowOrder.push(STEP.shareToCompany);
    }
    // share to network flow, if configured and not an incentive action
    if (
      features.shareToNetwork &&
      isConfigured.shareToNetwork(data.shareToNetwork) &&
      !(
        data.incentives?.baseAction === 'shareToNetwork' ||
        data.incentives?.raisedAction === 'shareToNetwork'
      )
    ) {
      flowOrder.push(STEP.shareToNetwork);
    }
    // collect email before incentives
    if (features.chatFlow && isConfigured.chatFlow(data.chatFlow)) {
      /**
       * get the email if we don't have it
       * when chat flow is not used, this is collected on the user info form
       */
      flowOrder.push(STEP.collectEmail);
    }
    flowOrder.push(STEP.incentives);
  } else {
    /**
     * when no incentives are configured, all incentivizable features should be run independently
     */
    // share to company
    if (
      features.shareToCompany &&
      isConfigured.shareToCompany(data.shareToCompany) &&
      !(
        data.incentives?.baseAction === 'shareToCompany' ||
        data.incentives?.raisedAction === 'shareToCompany'
      )
    ) {
      flowOrder.push(STEP.shareToCompany);
    }
    // share to network
    if (
      features.shareToNetwork &&
      isConfigured.shareToNetwork(data.shareToNetwork)
    ) {
      flowOrder.push(STEP.shareToNetwork);
    }
    // collect email
    if (features.chatFlow && isConfigured.chatFlow(data.chatFlow)) {
      /**
       * get the email if we don't have it
       * when chat flow is not used, this is collected on the user info form
       */
      flowOrder.push(STEP.collectEmail);
    }
  }

  return flowOrder;
};

const FeedbackPage = ({
  company,
  pageData,
  features,
  virtualSalesPageData,
}: FeedbackPageProps): JSX.Element => {
  const router = useRouter();

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (router.isFallback) {
    return <Loader />;
  }

  // This includes setting the noindex header because static files always return
  // a status 200 but the rendered not found page page should obviously not be indexed
  if (!company) {
    return (
      <>
        <Head>
          <meta name='robots' content='noindex' />
        </Head>
        <DefaultErrorPage statusCode={404} />
      </>
    );
  }

  // Get the ordered steps of the flow for this company
  const flowSteps = getFlowSteps(features, pageData);

  // manage the active step in the flow
  const [activeStep, setActiveStep] = useState(flowSteps[0]);

  // initialize the testimonial with a minimal default
  const [testimonial, setTestimonial] = useState<ITestimonial>({
    companyId: company.uid,
    type: 'written',
    status: 'pending',
    rating: null,
  });

  // Initials feedback Id. This will be used to update the feedback
  // with user info at CollectUserInfo and CollectEmail stage
  const [newFeedbackId, setNewFeedbackId] = useState('');

  // Hide page header on mobile when recording a video testimonial
  // on mobile
  const [hideHeader, setHideHeader] = useState(false);

  // handle the transition from one flow step to the next
  const continueFlow = (rating?: number) => {
    // go to resolution flow if company has feature enabled and included
    // in the review process and the reviewer rated less than 4 stars
    const goToResolution =
      features.resolutionFlow &&
      !pageData?.resolutionFlow?.excludeFromCollectionFlow &&
      rating &&
      rating <= 3;

    if (!goToResolution) {
      // continue default flow
      const index = flowSteps.indexOf(activeStep);
      const next = index + 1;
      if (next === flowSteps.length) {
        // end the flow if already on the last step
        endFlow();
      } else {
        // otherwise, go to the next step
        setActiveStep(flowSteps[next]);
      }
    } else {
      // go to the resolution process
      if (activeStep === STEP.resolutionFlow) {
        // end the flow if already on resolution step
        endFlow();
      } else {
        // go to resolution flow
        setActiveStep(STEP.resolutionFlow);
      }
    }
  };

  // end the flow and take the user to the final destination
  const endFlow = () => {
    const { companySlug } = router.query;

    /** take user to the custom testimonial thank you page */
    if (virtualSalesPageConfigured(company, virtualSalesPageData)) {
      router.replace(
        `/[companyName]/testimonial-thankyou`, // pathname
        `/${companySlug}/testimonial-thankyou` // as
      );
    } else if (validateURL(pageData?.redirectURL)) {
      /** take the user to the specified redirect URL or */
      window.location.replace(pageData.redirectURL);
    } else {
      /** take the user to the client landing page, if no redirect is specified */
      router.replace(
        `/[companyName]/thanks`, // pathname
        `/${companySlug}/thanks` // as
      );
    }
  };

  const resolutionEndFlow = () => {
    const { companySlug } = router.query;

    router.replace(
      `/[companyName]/feedback-thanks`, // pathname
      `/${companySlug}/feedback-thanks` // as
    );
  };

  const requestDelayResolutionEndFlow = () => {
    const { companySlug } = router.query;

    router.replace(
      `/[companyName]/feedback-contact`, // pathname
      `/${companySlug}/feedback-contact` // as
    );
  };

  return (
    <>
      <BrandedPageHead
        pageData={pageData}
        pageName={`${company.name ? company.name + ' ' : ''}Feedback`}
      />
      <BrandedPage
        name={company.name || ''}
        logo={company.branding?.logo || null}
        colorPrimary={company.branding?.colorPrimary || null}
        stickyHeader
        hideHeader={hideHeader}
      >
        {isAccountPaused(company) ? (
          <PausedPublicPageDefault companyName={company.name} />
        ) : (
          <>
            {/* CHAT FLOW (COLLECT USER INFO + RATING AS A STEPPER) */}
            {activeStep === STEP.chatFlow && (
              <ChatFlow
                company={company}
                data={pageData?.chatFlow}
                testimonial={testimonial}
                setTestimonial={setTestimonial}
                continueFn={continueFlow}
              />
            )}

            {/* FEEDBACK FLOW (GET ANSWERS TO CUSTOM FEEDBACK QUESTIONS) */}
            {activeStep === STEP.feedbackFlow && (
              <FeedbackFlow
                company={company}
                data={pageData?.feedbackFlow}
                continueFn={continueFlow}
                setNewFeedbackId={setNewFeedbackId}
              />
            )}

            {/* COLLECT WRITTEN OR VIDEO TESTIMONIAL */}
            {activeStep === STEP.collectTestimonial && (
              <CollectTestimonial
                company={company}
                data={pageData?.testimonial}
                features={features}
                testimonial={testimonial}
                setTestimonial={setTestimonial}
                continueFn={continueFlow}
                setHideHeader={setHideHeader}
              />
            )}

            {/* COLLECT USER INFO + RATING IN A FORM */}
            {activeStep === STEP.collectUserInfo && (
              <CollectUserInfo
                company={company}
                privacyPolicyURL={pageData?.privacyPolicyURL || ''}
                testimonial={testimonial}
                setTestimonial={setTestimonial}
                continueFn={continueFlow}
                feedbackId={newFeedbackId}
              />
            )}

            {/* RESOLUTION FLOW (HANDLE LOW RATINGS) */}
            {activeStep === STEP.resolutionFlow && (
              <ResolutionFlow
                company={company}
                data={pageData?.resolutionFlow}
                shareToCompany={pageData?.shareToCompany}
                testimonial={testimonial}
                endFlow={resolutionEndFlow}
                incentivesData={pageData?.incentives}
                agreeToDelayEndFlow={requestDelayResolutionEndFlow}
              />
            )}

            {/* INCENTIVIZE SHARES */}
            {activeStep === STEP.incentives && (
              <Incentives
                company={company}
                data={pageData}
                testimonial={testimonial}
                continueFn={continueFlow}
              />
            )}

            {/* SHARE TO COMPANY */}
            {activeStep === STEP.shareToCompany && (
              <ShareToCompany
                data={pageData?.shareToCompany}
                testimonial={testimonial}
                continueFn={continueFlow}
              />
            )}

            {/* SHARE TO NETWORK */}
            {activeStep === STEP.shareToNetwork && (
              <ShareToNetwork
                company={company}
                data={pageData?.shareToNetwork}
                continueFn={continueFlow}
              />
            )}

            {/* COLLECT EMAIL */}
            {activeStep === STEP.collectEmail && (
              <>
                <CollectEmail
                  company={company}
                  testimonial={testimonial}
                  privacyPolicyURL={pageData?.privacyPolicyURL || ''}
                  continueFn={continueFlow}
                  feedbackId={newFeedbackId}
                />
              </>
            )}
          </>
        )}
      </BrandedPage>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ params }) => {
  // Get company data
  const company: ICompany = await getCompanyInfo(params.companySlug as string);

  // Get company page customization data
  let pageData = null;
  let virtualSalesPageData = null;
  if (company) {
    pageData = await getCompanyPage(company.uid, 'feedback');
    virtualSalesPageData = await getCompanyPage(company.uid, 'virtualsales');
  }

  // Pass the props to the page component
  return {
    props: {
      pageData: pageData || {},
      company: company,
      features: company?.features?.feedback || {},
      virtualSalesPageData: virtualSalesPageData || {},
    },
  };
};

export default FeedbackPage;
